import Select from 'react-select'
import { styled } from 'styled-components'

export const CampaignContainer = styled.div`
  background-color: var(--grey-0);
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow-y: auto;

  form {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    width: 40vw;
    padding: 16px 32px;

    label {
      color: var(--content-primary);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }
`

export const CustomAmountFormWrapper = styled(CampaignContainer)`
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 16px;
  overflow-x: hidden;

  > div {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  label {
    font-size: 24px;
    line-height: 52px;
    font-weight: bold;
  }

  .amount-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .amount-list {
    height: 160px;
  }

  .amount-modal-buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
  }
`

export const CustomAmountWrapper = styled.div`
  width: 100% !important;
  overflow: hidden;

  > table th,
  td {
    width: 25%;
    padding: 0px 8px;
    font-size: 12px;
  }
  > table th:second-of-type,
  td:second-of-type {
    width: 50%;
    padding: 0px 8px;
  }

  td:nth-child(3) {
    position: static;
    z-index: 1;
    padding-top: 8px;

    > div {
      position: static;

      > div:second-of-type {
        z-index: 4;
      }
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    gap: 8px;
    > div > div {
      position: static;
      width: 120px !important;
      height: 40px;
    }
    a {
      margin-top: 6px;
      svg {
        color: var(--red-500);
      }
    }
  }

  button {
    width: 100%;
  }
`

export const StylizedSelect = styled(Select)<{ $offsetTop: string }>`
  width: 120px !important;
  height: 40px;
  position: static;

  img {
    height: 24px;
  }
  > div:nth-of-type(1) {
    border: 1px solid green;
    padding: 1px;
    z-index: 0;
  }
  > div:nth-of-type(2) {
    border: 1px solid red;
    z-index: 5;
    top: ${props => (props.$offsetTop ? `${props.$offsetTop}px` : '100%')};
    width: 120px;
  }
`

export const CampaignToggleDiv = styled.div`
  &.campaign-toggle {
    > div {
      width: 64px;
      display: flex;
      gap: 4px;
      flex-direction: row-reverse;
      padding: 0px 8px;
    }
  }
`
