import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { CampaignEntity, TemplatePageProps } from '../../shared/types'
import { deleteCampaign, getOrganizationCampaignList } from './api'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { showNotification } from '../../shared/components/Alert'
import { setSelectedOrganization } from '../Organization/ducks/organizationSlice'
import styles from './Campaign.module.css'
import { hasPermission } from '../../shared/utils'
import { RolePermission } from '../../shared/constants'
import Button from '../../shared/components/button/Button'
import Modal from '../../shared/components/modal/Modal'
import { Confirmation } from '../../shared/components/confirmation/Confirmation'
import { Title } from '../../shared/components/title/Title'
import { TextInput } from '../../shared/components/input/TextInput'
import { Icon } from '../../shared/components/icon/Icon'
import { Select } from '../../shared/components/Select'
import Table from '../../shared/components/table/Table'
import { Wrapper } from '../Installations/Installations'
import { CampaignToggleDiv } from './styles'
import ActionToggle from '../../shared/components/input/ActionToggle'

export const Campaign = ({ user }: TemplatePageProps): React.ReactNode => {
  const navigate = useNavigate()
  const [campaignList, setCampaignList] = React.useState<CampaignEntity[]>([])
  const [isLoading, setIsLoading] = React.useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [toDelete, setToDelete] = useState(null)
  const [activeOnly, setActiveOnly] = React.useState(false)

  const orgList = useSelector(
    (state: RootState) => state.organization.organizationList,
  )
  const dispatch = useDispatch()
  const selectedOrg = useSelector(
    (state: RootState) => state.organization.selectedOrganization,
  )
  const [searchText, setSearchText] = useState('')
  const canUpdate = hasPermission(user, 'campaign', RolePermission.UPDATE)
  const canCreate = hasPermission(user, 'campaign', RolePermission.CREATE)
  const canDelete = hasPermission(user, 'campaign', RolePermission.DELETE)
  const canRead = hasPermission(user, 'campaign', RolePermission.READ)

  const doDeleteCampaign = () => {
    setIsLoading(true)
    setIsModalOpen(false)
    deleteCampaign(user, toDelete, selectedOrg?.orgId).then(() => {
      setIsLoading(false)
      showNotification('success', 'Campaign deleted')
    })
  }

  React.useEffect(() => {
    if (selectedOrg) return
    dispatch(setSelectedOrganization(orgList[0]))
  }, [])

  React.useEffect(() => {
    if (isLoading && user) {
      getOrganizationCampaignList(
        user,
        selectedOrg?.orgId ||
          (orgList.length && orgList[0].orgId) ||
          user.orgId,
      )
        .then((e: CampaignEntity[]) => {
          if (e) setCampaignList(e)
        })
        .catch(() => {
          showNotification('danger', 'Failed to load campaigns.')
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [isLoading, selectedOrg])

  React.useEffect(() => {
    setIsLoading(true)
    if (isLoading) setIsLoading(false)
  }, [activeOnly])

  const onSelectOrganization = (e: string) => {
    const org = orgList.find(value => value.orgId === e)
    dispatch(setSelectedOrganization(org))
    setIsLoading(true)
  }

  const filteredCampaigns = campaignList?.filter((value: CampaignEntity) => {
    return (
      value.name.toLowerCase().startsWith(searchText.toLowerCase()) &&
      (!activeOnly || value.isActive === true)
    )
  })

  return canRead ? (
    <>
      <LoadingOverlay isLoading={isLoading} />
      {isLoading ? null : (
        <Wrapper>
          {isModalOpen && (
            <Modal hasBackdrop onClose={() => setIsModalOpen(false)}>
              <Confirmation
                title="Are you sure?"
                message="This action cannot be undone."
                onConfirm={() => {
                  doDeleteCampaign()
                }}
                onCancel={() => setIsModalOpen(false)}
              />
            </Modal>
          )}
          {
            <>
              <Title>Campaigns</Title>
              <div className={styles.search}>
                <TextInput
                  value={searchText}
                  prefix={<Icon name="search.svg" />}
                  onChange={e => setSearchText(e.target.value)}
                />
                <div className={styles.organisation}>
                  <Select
                    isWithFader={false}
                    visibleCount={10}
                    collection={orgList
                      .map(org => ({
                        name: org.name,
                        value: org.orgId,
                      }))
                      .sort((a, b) => a.name.localeCompare(b.name))}
                    value={{
                      name: orgList.find(
                        org => org.orgId === selectedOrg?.orgId,
                      )?.name,
                      value: selectedOrg?.orgId,
                    }}
                    onSelect={org => onSelectOrganization(org)}
                    disabled={orgList.length < 2}
                  />
                </div>
                <CampaignToggleDiv className="campaign-toggle">
                  <ActionToggle
                    value={activeOnly}
                    setValue={(newValue: boolean) => {
                      setActiveOnly(newValue)
                    }}
                    label={'Active'}
                    name="activenlyFilter"
                  />
                </CampaignToggleDiv>
                {canCreate && (
                  <Button
                    onClick={() =>
                      navigate(`/campaign/${selectedOrg?.orgId || ''}/new`)
                    }
                  >
                    New Campaign
                  </Button>
                )}
              </div>
              <div className={styles.filter}>
                Showing {filteredCampaigns.length} campaigns (of{' '}
                {campaignList.length})
              </div>
              <Table
                className={styles.table}
                header={[
                  '#',
                  'Name',
                  'Start Date',
                  'Expiry Date',
                  'Last Update',
                  'Status',
                  'Actions',
                ]}
                data={filteredCampaigns.map(
                  (value: CampaignEntity, i: number) => {
                    return [
                      i + 1,
                      value.name,
                      value.createdAt,
                      value.expiryDate,
                      value.updatedAt,
                      value.isActive ? 'Active' : 'Inactive',
                      <div key={value.campaignId} className={styles.actions}>
                        {canUpdate && (
                          <div
                            onClick={() =>
                              navigate(
                                `/campaign/${selectedOrg?.orgId}/${value.campaignId}`,
                              )
                            }
                          >
                            <Icon name="edit.svg" />
                          </div>
                        )}
                        {canDelete && (
                          <div
                            onClick={() => {
                              setToDelete(value)
                              setIsModalOpen(true)
                            }}
                          >
                            <Icon name="trash.svg" />
                          </div>
                        )}
                      </div>,
                    ]
                  },
                )}
              />
            </>
          }
        </Wrapper>
      )}
    </>
  ) : (
    <h1>You do not have permission to use this function.</h1>
  )
}

const mapStateToProps = (state: RootState) => ({
  user: state.login.user,
})

export default connect(mapStateToProps)(Campaign)
